import React from 'react';
import { Box, Flex, Image, Heading, Text, Stack, useColorModeValue, Link, IconButton } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaLinkedin, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import profileImage from './R6__6641.jpg'; // Import the image
import Footer from './Footer';

const MotionBox = motion(Box);
const MotionImage = motion(Image);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const Home = () => {
  const bgColor = useColorModeValue('blue.700', 'blue.900');
  const textColor = useColorModeValue('white', 'gray.200');
  const AbtTextColor = useColorModeValue('blue.900', 'blue.900');
  const cardBgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('blue.500', 'blue.500');
  const aboutBgColor = useColorModeValue('gray.200', 'gray.800'); 

  return (
    <>
      <MotionBox
        id="home"
        bg={bgColor}
        color={textColor}
        minH="100vh"
        p={10}
        display="flex"
        alignItems="center"
        justifyContent="center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Flex
          direction={{ base: 'column', md: 'row' }}
          align="center"
          justify="center"
          maxW="1200px"
          mx="auto"
          boxShadow="xl"
          borderRadius="lg"
          overflow="hidden"
          bg={cardBgColor}
          position="relative"
        >
          <MotionBox flex="1" textAlign="center" p={5} bg="white" initial={{ x: -200 }} animate={{ x: 0 }} transition={{ duration: 1 }}>
            <MotionImage
              borderRadius="lg"
              boxSize="auto"
              src={profileImage} 
              alt="Profile Image"
              mx="auto"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            />
          </MotionBox>
          <Box flex="1" p={10} bg={bgColor} color={textColor}>
            <Stack spacing={5} textAlign={{ base: 'center', md: 'left' }}>
              <MotionHeading
                as="h2"
                size="xl"
                borderBottom="2px solid"
                borderColor={borderColor}
                pb={2}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                Engr. JOHNSON AWOYOMI
              </MotionHeading>
              <MotionText fontSize="lg" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                (BSc Hons) Chem. Eng., FNSE, FNSChE, FOSHA, FIMC, CCP, CEP, PMP
              </MotionText>
              <MotionText fontSize="lg" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.2 }}>
                <strong>Cost Engineer / Estimator</strong><br />
                <strong>Project Engineer</strong>
              </MotionText>
              <MotionText fontSize="md" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.4 }}>
                <strong>Email:</strong><br />
               jawoyomi@cengpcs.com<br />
              info@cengpcs.com
              </MotionText>
              <MotionText fontSize="md" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.6 }}>
                <strong>Date of Birth:</strong><br />
                February 12th
              </MotionText>
              <MotionText fontSize="md" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.8 }}>
                <strong>Company Website:</strong><br />
                <Link href="http://www.cengpcs.com" target="_blank" rel="noopener noreferrer" color={textColor} _hover={{ textDecoration: 'underline', color: 'blue.200' }}>
                  www.cengpcs.com
                </Link>
              </MotionText>
            </Stack>
          </Box>
        </Flex>
      </MotionBox>
      <Flex justify="center" mt={-5}>
        <Box bg={cardBgColor} borderRadius="full" p={1} boxShadow="lg">
          <Flex direction="row" spacing={4}>
            <Link href="https://www.linkedin.com/in/johnson-awoyomi/" isExternal>
              <IconButton aria-label="LinkedIn" icon={<FaLinkedin />} size="lg" variant="ghost" colorScheme="blue" />
            </Link>
            <Link href="https://www.facebook.com/johnson.awoyomi.3" isExternal>
              <IconButton aria-label="Facebook" icon={<FaFacebook />} size="lg" variant="ghost" colorScheme="blue" />
            </Link>
            <Link href="https://x.com/AwoyomiJohnson" isExternal>
              <IconButton aria-label="Twitter" icon={<FaTwitter />} size="lg" variant="ghost" colorScheme="blue" />
            </Link>
            <Link href="https://www.instagram.com/awoyomijohnson/" isExternal>
              <IconButton aria-label="Instagram" icon={<FaInstagram />} size="lg" variant="ghost" colorScheme="blue" />
            </Link>
          </Flex>
        </Box>
      </Flex>
      <Box mt={10} p={10} w="100%" bg={bgColor} borderRadius="lg" boxShadow="xl">
        <Flex direction="column" align="center" maxW="1200px" mx="auto">
          <MotionHeading as="h3" size="lg" mb={5} color={textColor} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            About Me
          </MotionHeading>
          <MotionText fontSize="md" color={textColor} textAlign="justify" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.2 }}>
            I am a dedicated and experienced Cost Engineer / Estimator and Project Engineer with a demonstrated history of working in the chemical engineering industry. Skilled in cost estimation, project management, and engineering design, I have a strong background in delivering successful projects on time and within budget. My passion for engineering and commitment to excellence drives me to continually seek innovative solutions and improvements in every project I undertake. With a solid educational foundation and numerous certifications, I am well-equipped to handle complex engineering challenges and contribute to the success of any organization.
          </MotionText>
        </Flex>
      </Box>
      {/* <Footer /> */}
    </>
  );
};

export default Home;
