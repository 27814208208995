import React from 'react';
import { Box, Flex, Heading, Text, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaSmile, FaFileAlt, FaGlobe, FaBolt, FaStar } from 'react-icons/fa';
import Footer from './Footer';

const programs = [
  {
    title: 'Cost Engineering and Project Management Training Programs (4 Levels) – CEPM – for PIA institutions',
    icon: FaSmile,
  },
  {
    title: 'Advanced Project Management - Project Planning & Scheduling, Cost Engineering and Risk Management – APM',
    icon: FaFileAlt,
  },
  {
    title: 'Capital Cost Estimating: Its Preparation, Review, Validation and Benchmarking Principles and Practices Training Program – CEB',
    icon: FaGlobe,
  },
  {
    title: 'Project Constructing Planning and Scheduling Training Program – PCPS',
    icon: FaBolt,
  },
  {
    title: 'Cost Engineering Certification Training Packages – CCP',
    icon: FaSmile,
  },
  {
    title: 'Cost Engineering and Project Controls Training – CEPC',
    icon: FaStar,
  },
  {
    title: 'Project Changes and Claims Management for EPC Training Program – PCCM',
    icon: FaSmile,
  },
  {
    title: 'Bidding and Winning Projects Competitively and Profitably – BWPC',
    icon: FaFileAlt,
  },
  {
    title: 'Cost Benchmarking Capacity Building (of Capital Projects) – Principles and Best Practices',
    icon: FaGlobe,
  },
  {
    title: 'Cost Control of crude oil extracting in a developing economy – CCC',
    icon: FaBolt,
  },
  {
    title: 'Building a Culture of Resilience and Adaptability at at par with standards and practices in the global oil and gas industry – BCRA',
    icon: FaSmile,
  },
  {
    title: 'Planning & Scheduling, Cost Estimate Preparation and Review of Abandonment and Decommissioning Projects – PSCE, etc',
    icon: FaStar,
  },
  {
    title: 'Serving several cost professionals & stakeholders worldwide to take their project controls and cost engineering skills to the next level.',
    icon: FaSmile,
  },
  {
    title: 'Project and Portfolio benchmarking',
    icon: FaFileAlt,
  },
  {
    title: 'Project Probabilistic Cost and Schedule Risk Analysis',
    icon: FaGlobe,
  },
  {
    title: 'Decommissioning and Abandoning Cost Estimation – Principles and practices',
    icon: FaStar,
  },
  {
    title: 'Advanced Planning and Scheduling – Project Planning, Scheduling, Cost Engineering and Risk Management',
    icon: FaBolt,
  },
];

const CapacityDevelopment = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('blue.700', 'white');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('blue.500', 'blue.300');

  return (
    <> 
    <Box id="capacity-development" bg={bgColor} color={textColor} py={20}>
      <Flex direction="column" align="center" maxW="1200px" mx="auto" px={5} mt={16}>
        <Heading as="h3" size="xl" mb={10} borderBottom="4px solid" borderColor={borderColor} pb={2}>
          Capacity Development Programs
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {programs.map((program, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Flex
                direction="column"
                align="center"
                p={5}
                border="2px solid"
                borderColor={borderColor}
                bg={cardBgColor}
                boxShadow="lg"
                borderRadius="md"
                textAlign="center"
              >
                <Box as={program.icon} size="40px" mb={3} />
                <Text fontSize="lg" fontWeight="bold" mb={3}>{program.title}</Text>
              </Flex>
            </motion.div>
          ))}
        </SimpleGrid>
      </Flex>
    </Box>
    <Footer />
    </>
  );
};

export default CapacityDevelopment;
