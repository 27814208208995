// src/components/Footer.js
import React from 'react';
import { Box, Text, Stack, Link, IconButton, useColorModeValue, Flex } from '@chakra-ui/react';
import { FaLinkedin, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  const bgColor = useColorModeValue('blue.700', 'blue.700');
  const color = useColorModeValue('white', 'gray.200');

  return (
    <Box bg={bgColor} color={color} py={4}>
      <Flex maxW="1200px" mx="auto" justify="space-between" align="center" px={5}>
        <Text fontSize={'sm'}>
          © {new Date().getFullYear()} Johnson Awoyomi. All rights reserved.
        </Text>
        <Stack direction="row" spacing={4}>
          <Link href="https://www.linkedin.com/in/johnson-awoyomi/" isExternal>
            <IconButton aria-label="LinkedIn" icon={<FaLinkedin />} size="lg" variant="ghost" color="white" />
          </Link>
          <Link href="https://www.facebook.com/johnson.awoyomi.3" isExternal>
            <IconButton aria-label="Facebook" icon={<FaFacebook />} size="lg" variant="ghost" color="white" />
          </Link>
          <Link href="https://x.com/AwoyomiJohnson" isExternal>
            <IconButton aria-label="Twitter" icon={<FaTwitter />} size="lg" variant="ghost" color="white" />
          </Link>
          <Link href="https://www.instagram.com/awoyomijohnson/" isExternal>
            <IconButton aria-label="Instagram" icon={<FaInstagram />} size="lg" variant="ghost" color="white" />
          </Link>
        </Stack>
      </Flex>
    </Box>
  );
};

export default Footer;
