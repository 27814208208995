import React from 'react';
import { Box, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Footer from './Footer';

const technicalPapers = [
  {
    title: 'Owner cost optimization techniques',
    description: 'Presented at a Conference Western Winter Workshop (WWW) in California on March 1, 2024.',
  },
  {
    title: 'Maximizing the Government Take from the Oil Sector – Cost Engineering perspectives',
    description: 'Presented at the 2019 AACE International Western Winter Workshop Annual Meeting held at the Lake Tahoe, Nevada, USA in March 2019. Also presented in Nigeria NQ Annual Conference, in November 2019, at PTDF Office, Abuja.',
  },
  {
    title: 'Optimizing the cost of crude oil production – NOC perspectives',
    description: 'Presented at the 2018 AACE International Annual Meeting held at Hyatt Regency, San Diego, USA; June 2017.',
  },
  {
    title: 'Cost Benchmarking of a Joint Venture Project',
    description: 'Presented at the 2017 AACE International Annual Meeting held at Hyatt Regency, Florida, Orlando, USA, June 11-14, 2017.',
  },
  {
    title: 'Owner’s Cost Estimating Processes and Practices – NOC Perspectives',
    description: 'Presented at the 2014 Annual meeting of the Association for Advancement of cost Engineering (AACE) International at New Orleans, USA in June 2014. This Paper was published as one of the best Technical Articles in the May/June edition of "Cost Engineering" Journal; 2014.',
  },
  {
    title: 'Delivery of Successful National Oil Company Turn Around Maintenance As-Is Reporting Versus EVM Reporting',
    description: 'Presented at the 2015 AACE Western Winter Conference, in February 2015, at the Lake Tahoe, Nevada, USA.',
  },
  {
    title: 'Best Practices In Total Cost Management And Successful Delivery Of Major Offshore Project',
    description: 'Presented at the 2016 Offshore West African Conference at Eko Hotel, Lagos.',
  },
  {
    title: 'Improved Turn-Around Maintenance of Refineries though the Application of Project Control',
    description: 'This Paper was presented and awarded 2nd Price for the 1995 Young Managers’ Competition Zone 2, Lagos, organised by the Nigerian Institute of Management (NIM). "Cutting costs in NETCO\'s businesses – The relevant drivers"; this paper was requested for circulation among every staff for guidance; etc.',
  },
  {
    title: 'Cost Engineering in Oil and Gas Capital Projects',
    description: 'Presented at NAPIMS (now NNPC Upstream Investment Management) & CHEVRON Project Managers PSC Optimization Workshop, Aug 24-25, 2022, Dubai.',
  },
];

const TechnicalPapers = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('blue.700', 'white');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('blue.500', 'blue.300');

  return (
    <> 
    <Box id="technical-papers" bg={bgColor} color={textColor} py={20}>
      <Flex direction="column" align="justify" maxW="1200px" mx="auto" px={5} mt={16}>
        <Heading as="h3" size="xl" mb={10} borderBottom="4px solid" borderColor={borderColor} pb={2} align="center">
          Technical Papers & Publications
        </Heading>
        {technicalPapers.map((paper, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <Flex
              direction={{ base: 'column', md: 'row' }}
              mb={10}
              p={5}
              borderLeft="4px solid"
              borderColor={borderColor}
              bg={cardBgColor}
              boxShadow="lg"
              borderRadius="md"
              overflow="hidden"
              align="start"
            >
              <Box flex="1" pr={{ md: 5 }} mb={{ base: 5, md: 0 }}>
                <Text fontWeight="bold" fontSize="xl">{paper.title}</Text>
                <Text fontSize="md" align="justify">{paper.description}</Text>
              </Box>
            </Flex>
          </motion.div>
        ))}
      </Flex>
    </Box>
    <Footer />
    </>
  );
};

export default TechnicalPapers;
