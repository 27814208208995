import React from 'react';
import { Box, Heading, Text, SimpleGrid, useColorModeValue, Center, Image, Link } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import servicesImage from './services_cepcs.png';
import Footer from './Footer';

import lateejay from './images/lateejay.jpeg';
import MPR from './images/MPR.jpeg';
import NCDMB from './images/NCDMB.png';
import NNPC from './images/NNPC.png';
import NUPCR from './images/NUPCR.jpeg';
import WAEP from './images/WAEP.png';
import PTDF from './images/PTDF.jpeg';
import Petronet from './images/Petronet.jpeg';
import HeritageOil from './images/HeritageOil.png';
import JuliusBerger from './images/Juliusberger.png';
import WAGPC from './images/WAGPC.jpeg';
import AGMC from './images/AGMC.jpeg';
import ALCON from './images/ALCON.png';
import BEAMCO from './images/BEAMCO.jpeg';
import GACN from './images/GACN.png';
import NLNG from './images/NLNG.png';

const expertise = [
  'Cost Engineering',
  'Cost Estimation',
  'Project Management',
  'Planning & Scheduling',
  'Value for Money Audit',
  'Risk Analysis',
  'Cost Estimate Review',
  'Cost Estimate Validation',
  'Cost Estimate Benchmarking'
];

const clients = [
  NNPC,
  MPR,
  JuliusBerger,
  WAEP,
  PTDF,
  NCDMB,
  NUPCR,
  HeritageOil,
  Petronet,
  WAGPC,
  AGMC,
  ALCON,
  BEAMCO,
  GACN,
  NLNG,
  lateejay
];

const MotionBox = motion(Box);

const Expertise = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const cardBgColor = useColorModeValue('white', 'gray.600');
  const textColor = useColorModeValue('blue.700', 'white');
  const clientsBgColor = useColorModeValue('white', 'gray.600');

  return (
    <>
      <Box id="expertise" bg={bgColor} color={textColor} py={17}>
        <Center mt={16}>
          <Heading as="h3" size="xl" mb={10} mt={16} align="center" pb={2} position="relative" _after={{
            content: '""',
            width: '100%',
            height: '4px',
            backgroundColor: textColor,
            position: 'absolute',
            bottom: 0,
            left: 0
          }}>
            Expertise
          </Heading>
        </Center>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10} maxW="1200px" mx="auto" px={5}>
          {expertise.map((item, index) => (
            <MotionBox
              key={index}
              p={5}
              bg={cardBgColor}
              borderRadius="md"
              boxShadow="lg"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              textAlign="center"
            >
              <Text fontSize="xl" fontWeight="bold">{item}</Text>
            </MotionBox>
          ))}
        </SimpleGrid>
        <Box maxW="1200px" mx="auto" mt={10} mb={5} px={5} textAlign="center">
          <Center>
            <Image src={servicesImage} alt="CEPCS' Services and Products" mb={5} />
          </Center>
          <Text fontSize="xl" fontWeight="bold">
            For more information, check out <Link href="http://cengpcs.com" isExternal color="blue.500">cengpcs.com</Link> or email: <Link href="mailto:info@cengpcs.com" color="blue.500">info@cengpcs.com</Link>
          </Text>
        </Box>
      </Box>
      
      <Box id="clients" bg={clientsBgColor} color={textColor} py={20}>
        <Center>
          <Heading as="h3" size="xl" mb={10} mt={2} align="center" pb={2} position="relative" _after={{
            content: '""',
            width: '100%',
            height: '4px',
            backgroundColor: textColor,
            position: 'absolute',
            bottom: 0,
            left: 0
          }}>
            Some Clients
          </Heading>
        </Center>
        <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={10} maxW="1200px" mx="auto" px={5}>
          {clients.map((logo, index) => (
            <MotionBox
              key={index}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              textAlign="center"
            >
              <Image src={logo} alt={`Client logo ${index + 1}`} boxSize="150px" objectFit="contain" mx="auto" />
            </MotionBox>
          ))}
        </SimpleGrid>
      </Box>
      
      <Footer />
    </>
  );
};

export default Expertise;
