// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Experience from './components/Experience';
import Awards from './components/Awards';
import Expertise from './components/Expertise';
import TechnicalPapers from './components/TechnicalPapers';
import CapacityDevelopment from './components/CapacityDevelopment';
import MembershipsCertifications from './components/MembershipsCertifications';
import Contact from './components/Contact';
import PurchaseBooks from './components/PurchaseBooks';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/awards" element={<Awards />} />
        <Route path="/expertise" element={<Expertise />} />
        <Route path="/books" element={<PurchaseBooks />} />
        <Route path="/technical-papers" element={<TechnicalPapers />} />
        <Route path="/capacity-development" element={<CapacityDevelopment />} />
        <Route path="/membership-certifications" element={<MembershipsCertifications />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
