import React from 'react';
import { Box, Flex, Heading, Text, Stack, Image, Link, useColorModeValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import fuelscarcity from './images/fuelscarcity.jpeg';
import Medium_and_large from './images/Medium_and_large.jpeg';
import max_revenue from './images/max_revenue.jpg';
import Footer from './Footer';


const books = [
  {
    title: 'THE COST OF FUEL SCARCITY IN NIGERIA',
    author: 'Johnson Awoyomi',
    frontCover: fuelscarcity,
    description: 'THE COST OF FUEL SCARCITY IN NIGERIA provides a simple-to-read and understand narrative of the concept of ‘War Rooms’ in the resolution of fuel scarcity in Nigeria. The first two chapters provide an introduction on what fuel scarcity is and its causes, the concepts of Fuel War Room - its operations and governance. The 3rd and 4th chapter look at typical “Standard Operating Procedures” (SOPs) that were developed and deployed in the Fuel War Room and their applications. The chapter 6 is explosive as it provides a comprehensive answer to the cost of fuel scarcity for a country like Nigeria using the principles of Total Cost Management. The last chapter provides answers to the questions: What next; is the cost of fuel scarcity sustainable or not? What options are available: Deregulate or not; Focus on renewables – wind, solar, hydrogen, carbon dioxide, etc. And finally provides some short and long terms remedies to deal with the perennial monster called "Fuel Scarcity".',
    eCopyLink: 'https://payhip.com/b/XRnkL',
    hardCopyLink: 'https://www.amazon.com/COST-FUEL-SCARCITY-Johnson-Awoyomi/dp/B09TF419MJ/ref=sr_1_2?crid=280OMUTEO684L&dib=eyJ2IjoiMSJ9.vFKMwS79ygyuq7OF1g5YOcw1PhGSWqVkUWWbjR0EWk0.3_PTzAGSxs2xsmBPJhHPcAciqUTgb1_pd0GX5fNqMY8&dib_tag=se&keywords=johnson+awoyomi&qid=1719356545&sprefix=johnson+awoyomi%2Caps%2C160&sr=8-2',
  },
  {
    title: 'COST ENGINEERING AND COST CONTROL OF MEDIUM AND LARGE CAPITAL PROJECTS',
    author: 'Johnson Awoyomi',
    frontCover: Medium_and_large,
    description: 'COST ENGINEERING AND COST CONTROL OF MEDIUM TO MAJOR CAPITAL PROJECTS is a ready-made hands-on material for project management practitioners—project engineers, project managers, cost engineers and project controllers, project owners and contractors for a successful project delivery. This book is a manual introducing the principles of Cost Engineering to beginners in Cost Engineering and Project Cost Controls. It contains some simple-to-apply techniques and tools on cost control, of both large and medium-sized projects. It also x-rays the imperatives of Cost Engineering principles concerning capital programs management efficiency and discipline.',
    eCopyLink: 'https://payhip.com/b/36jBo',
    hardCopyLink: 'https://www.amazon.com/ENGINEERING-CONTROL-MEDIUM-CAPITAL-PROJECT/dp/B09TDVR5MY/ref=sr_1_1?crid=DOVU4C6AUIS1&dib=eyJ2IjoiMSJ9.vFKMwS79ygyuq7OF1g5YOcw1PhGSWqVkUWWbjR0EWk0.3_PTzAGSxs2xsmBPJhHPcAciqUTgb1_pd0GX5fNqMY8&dib_tag=se&keywords=johnson+awoyomi&qid=1719318030&sprefix=johnson+awoyomi%2Caps%2C143&sr=8-1',
  },
  {
    title: 'MAXIMISING GOVERNMENT NET REVENUES FROM NIGERIAN OIL, GAS AND ENERGY SECTOR',
    author: 'Johnson Awoyomi',
    frontCover: max_revenue,
    description: 'MAXIMISING GOVERNMENT NET REVENUES FROM NIGERIAN OIL, GAS AND ENERGY SECTOR provides an insightful analysis on strategies to maximize government revenues from Nigeria\'s oil, gas, and energy sectors. Chapter one and two x-rays the imperatives of Cost Engineering for both capital management efficiency as well as national development. Chapter 4 deals with controlling the cost of crude oil production. Chapter 5 takes a cursory look at how to crash the contracting cycle in an oil, gas and energy environment. Chapter 7 deals with the concept of improving the owners\'s cost estimating process and practices—NOC perspectives. Chapter 9 focuses on developing historical projects database for the Nigerian Oil & Gas sector. ',
    eCopyLink: 'https://payhip.com/b/fFBY7',
    hardCopyLink: 'https://www.amazon.com/MAXIMISING-GOVERNMENT-REVENEUS-NIGERIA-ENERGY/dp/B09TF1JZNX',
  },
];

const MotionBox = motion(Box);
const MotionImage = motion(Image);

const PurchaseBooks = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('blue.700', 'white');
  const cardBgColor = useColorModeValue('white', 'gray.600');
  const linkHoverColor = useColorModeValue('blue.500', 'blue.300');
  const linkColor = useColorModeValue('blue.600', 'blue.300');
  const specialOrderColor = useColorModeValue('red.600', 'red.300');

  return (
    <>
    <Box id="books" bg={bgColor} color={textColor} py={20}>
      <Flex direction="column" align="center" maxW="1200px" mx="auto" px={5} mt={16}>
        <Heading as="h3" size="xl" mb={10} borderBottom="4px solid" borderColor={textColor} pb={2}>
          Purchase Engr. Johnson Awoyomi's Books
        </Heading>
        {books.map((book, index) => (
          <MotionBox
            key={index}
            mb={10}
            p={9}
            bg={cardBgColor}
            borderRadius="md"
            boxShadow="lg"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <Flex direction={{ base: 'column', md: 'row' }} align="center">
              <MotionImage
                src={book.frontCover}
                alt={`Front cover of ${book.title}`}
                boxSize="300px"
                objectFit="cover"
                borderRadius="md"
                mb={{ base: 5, md: 0 }}
                mr={{ md: 5 }}
              />
              <Stack spacing={3}>
                <Text fontWeight="bold" fontSize="xl">{book.title}</Text>
                <Text fontSize="lg">by {book.author}</Text>
                <Text fontSize="md" align={'justify'}>{book.description}</Text>
                <Stack direction="row" spacing={4}>
                  <Link href={book.eCopyLink} isExternal color={linkColor} _hover={{ color: linkHoverColor, textDecoration: 'underline' }}>
                    <Text fontWeight="bold" fontSize="lg">Purchase e-copy</Text>
                  </Link>
                  <Link href={book.hardCopyLink} isExternal color={linkColor} _hover={{ color: linkHoverColor, textDecoration: 'underline' }}>
                    <Text fontWeight="bold" fontSize="lg">Purchase hardcopy</Text>
                  </Link>
                </Stack>
              </Stack>
            </Flex>
          </MotionBox>
        ))}
        <Text fontSize="lg" mt={10} textAlign="center" color={specialOrderColor}>
          For special/mass orders, please email <Link href="mailto:info@cengpcs.com" color={specialOrderColor} _hover={{ color: linkHoverColor, textDecoration: 'underline' }}>info@cengpcs.com</Link>
        </Text>
      </Flex>
    </Box>
    <Footer />
    </>
  );
};

export default PurchaseBooks;
