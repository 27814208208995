import React from 'react';
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  useColorModeValue,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const Header = () => {
  const { isOpen, onToggle } = useDisclosure();
  const bgColor = useColorModeValue('blue.700', 'blue.700');
  const color = useColorModeValue('white', 'white');
  const hoverColor = useColorModeValue('blue.300', 'blue.300');
  const activeColor = useColorModeValue('blue.500', 'blue.500');
  const borderColor = useColorModeValue('blue.700', 'blue.700');
  const location = useLocation();

  const navItems = [
    { label: 'HOME', href: '/' },
    { label: 'EXPERIENCE', href: '/experience' },
    { label: 'AWARDS', href: '/awards' },
    { label: 'EXPERTISE/CLIENTS', href: '/expertise' },
    { label: 'PURCHASE BOOKS', href: '/books' },
    { label: 'PAPERS/PUBLICATIONS', href: '/technical-papers' },
    { label: 'DEVELOPMENT PROGRAMS', href: '/capacity-development' },
    { label: 'CERTIFICATIONS', href: '/membership-certifications' },
    { label: 'CONTACT', href: '/contact' },
  ];

  React.useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <Box>
      <Flex
        bg={bgColor}
        color={color}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={borderColor}
        align={'center'}
        position="fixed"
        width="100%"
        zIndex="1000"
        justifyContent="space-between"
      >
        <Flex flex={{ base: 1 }} justify={{ base: 'start', md: 'start' }}>
          <RouterLink to="/">
            <Text
              textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
              fontFamily={'heading'}
              color={color}
              fontWeight="bold"
              fontSize="2xl"
              cursor="pointer"
            >
              JOHNSON AWOYOMI
            </Text>
          </RouterLink>
        </Flex>

        <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
          <DesktopNav navItems={navItems} hoverColor={hoverColor} activeColor={activeColor} color={color} />
        </Flex>

        <Flex display={{ base: 'flex', md: 'none' }} ml={10}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? (
                <CloseIcon w={3} h={3} />
              ) : (
                <HamburgerIcon w={5} h={5} />
              )
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
            color={color}
          />
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav navItems={navItems} hoverColor={hoverColor} activeColor={activeColor} color={color} />
      </Collapse>
    </Box>
  );
};

const DesktopNav = ({ navItems, hoverColor, activeColor, color }) => {
  return (
    <Stack direction={'row'} spacing={4}>
      {navItems.map((navItem) => (
        <RouterLink key={navItem.label} to={navItem.href}>
          <Text
            p={2}
            fontSize={'sm'}
            fontWeight={500}
            color={color}
            _hover={{
              textDecoration: 'underline',
              color: hoverColor,
            }}
            _active={{
              textDecoration: 'underline',
              color: activeColor,
              border: '1px solid',
              borderColor: activeColor,
              borderRadius: 'md',
            }}
            cursor="pointer"
          >
            {navItem.label}
          </Text>
        </RouterLink>
      ))}
    </Stack>
  );
};

const MobileNav = ({ navItems, hoverColor, activeColor, color }) => {
  return (
    <Stack
      bg={useColorModeValue('blue.700', 'blue.700')}
      p={4}
      display={{ md: 'none' }}
    >
      {navItems.map((navItem) => (
        <RouterLink key={navItem.label} to={navItem.href}>
          <Text
            py={2}
            fontSize={'sm'}
            fontWeight={500}
            color={color}
            _hover={{
              textDecoration: 'underline',
              color: hoverColor,
            }}
            _active={{
              textDecoration: 'underline',
              color: activeColor,
              border: '1px solid',
              borderColor: activeColor,
              borderRadius: 'md',
            }}
            cursor="pointer"
          >
            {navItem.label}
          </Text>
        </RouterLink>
      ))}
    </Stack>
  );
};

export default Header;
