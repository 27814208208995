import React from 'react';
import { Box, Flex, Heading, Text, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Footer from './Footer';

const memberships = [
  {
    title: 'Registered Engineer',
    organization: 'The Council for the Regulation of Engineering in Nigeria (COREN).',
  },
  {
    title: 'Member, Association of Cost Engineers, London',
    organization: 'Association of Cost Engineers, London',
  },
  {
    title: 'Member, Association for the Advancement of Cost Engineering (AACE) International',
    organization: 'AACE International',
  },
  {
    title: 'Fellow, Nigerian Society of Chemical Engineers',
    organization: 'Nigerian Society of Chemical Engineers',
  },
  {
    title: 'Certified Cost Professional (CCP)',
    organization: 'AACE International',
  },
  {
    title: 'Certified Estimating Professional (CEP)',
    organization: 'AACE International',
  },
  {
    title: 'Fellow, Nigerian Society of Engineers',
    organization: 'Azure Expert',
  },
  {
    title: 'Project Management Professional (PMP)',
    organization: 'Project Management Institute (PMI)',
  },
  {
    title: 'Fellow, Institute of Management Consultants',
    organization: 'Institute of Management Consultants',
  },
  {
    title: 'Fellow, Occupational Safety and Health Association',
    organization: 'Occupational Safety and Health Association (OSHAassociation)',
  },
];

const MembershipAndCertifications = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('blue.700', 'white');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('blue.500', 'blue.300');

  return (
    <> 
    <Box id="membership-certifications" bg={bgColor} color={textColor} py={20}>
      <Flex direction="column" align="center" maxW="1200px" mx="auto" px={5} mt={16}>
        <Heading as="h3" size="xl" mb={10} borderBottom="4px solid" borderColor={borderColor} pb={2}>
          Membership & Certifications
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          {memberships.map((membership, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Flex
                direction="column"
                p={5}
                border="2px solid"
                borderColor={borderColor}
                bg={cardBgColor}
                boxShadow="lg"
                borderRadius="md"
                textAlign="center"
              >
                <Text fontSize="lg" fontWeight="bold" mb={2}>{membership.title}</Text>
                <Text fontSize="md">{membership.organization}</Text>
              </Flex>
            </motion.div>
          ))}
        </SimpleGrid>
      </Flex>
    </Box>
    <Footer />
    </>
  );
};

export default MembershipAndCertifications;
