import React from 'react';
import { Box, Flex, Heading, Text, useColorModeValue, Stack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Footer from './Footer';

const experiences = [
  {
    date: 'Mar 2022 – Present',
    title: 'Principal Consultant',
    company: 'Cost Engineering and Project Control Services (CENGPCS)',
    description: [
      'CENGPCS is a professional Project Management, Project Controls and Cost Engineering consulting company, dedicated to serving the needs of the process as well as construction industry. We provide professional cost engineering, project controls, cost estimation, planning and scheduling consulting services tailored to meet our customers\' needs.',
      '"Delivering high-impact projects with ease".',
      'The above underscores why CENGPCS considers it an urgent need to help our clients execute projects successfully. Therefore, we collaborate with our clients to develop a better scope of work, from which we prepare a reliable schedule and accurate cost estimate. In addition, we assist in developing the tender documents, evaluating them, and recommending for award. We ensure that we implement all the baselines – scope, schedule, cost, and quality, as awarded. We also ensure that all projects are on schedule and within the budget.',
    ],
  },
  {
    date: 'Jul 2020 – June 2022',
    title: 'Regional Director',
    company: 'Association for the Advancement of Cost Engineering (AACE)',
    description: [
      'Regional Director of the Region 9 (covering Africa and Europe) of the AACE International.',
    ],
  },
  {
    date: 'Aug 2021 – Feb 2022',
    title: 'Chief Executive Officer / Managing Director',
    company: 'National Engineering and Technical Company (NETCO)',
    description: [
      'Returned NETCO from a loss position of N1.76 Billion in 2020 to a stable position of over N4.2Billion profit before tax in December 2021.',
      'Introduced additional streams of revenue to NETCO – such as the provision of Cost Estimating Services; Provision of Value for Money Audits to the IOCs, NAPIMS, NPDC, etc.',
      'Initiated programs that are consistent with NETCO’s policy and objectives and introduced cost optimization strategies to improve the market position of the NETCO brand.',
      'Organized a 2-day seminar on “RE-POSITIONING NETCO FOR GROWTH AND PROFITABILITY IN PIA TIMES” where world-class consultants like McKinsey, Woodmack, Accenture (Vekaris), etc., were brought on board to share best practices knowledge with NETCO Management staff.',
      'Restored NETCO’s clients confidence in its ability to deliver projects on schedule and within budgets.',
      'Reshuffled the staff within NETCO for improved performance.',
      'Reform and institutionalize transparency, accountability and efficiency in the oil and gas industry.',
    ],
  },
  {
    date: '2017 – 2021',
    title: 'President, AACE Nigeria',
    company: 'Association for the Advancement of Cost Engineering (AACE)',
    description: [
      'President of the Nigerian Chapter of the AACE International.',
    ],
  },
  {
    date: 'Jul 2019 – Aug 2021',
    title: 'Group General Manager (GGM) – Engineering and Technology Division (ETD)',
    company: 'Nigerian National Petroleum Corporation (NNPC)',
    description: [
      'Development of Historical Cost Database for NNPC.',
      'At the 2019 NAICE SPE Annual Conference on 5th August, 2019 at Eko Hotel in Lagos; I presented a Technical Paper titled: “Developing Historical Projects’ Database for the Nigerian Oil & Gas Sector; the Status, the Imperatives and the new Normal”. The GMD – Malam Mele Kolo Kyari was in attendance and invited me to transform the Technical Paper to a Project for NNPC by developing NNPC Historical Cost Database.',
      'The project was completed on time and operationalized in NNPC today. The benefits of this project to NNPC:',
      '1. Better decision making (empirical/databased decision) – for NNPC Management.',
      '2. Provision of historical compasses for future strategic decisions for NNPC Management.',
      '3. Development and Retention of institutional memories for the Corporation.',
      '4. Provides an opportunity for a “learning organization” - Project processes improvement over the years/selection of right projects.',
      '5. A veritable tool for Cost Benchmarking (great value service to the corporation).',
      '6. Can improve Competitive Position - Know your history, predict your future.',
      '7. Will help in lowering Operational Cost - Increase quality /speed of historical analysis and reduce time to generate Order of Magnitude estimates.',
      '8. Etc.',
      'In addition to the development of the NNPC Historical Cost Database, other projects were executed in ETD encompassing – the ISPS NIMASA Port and Jetty project, Towers Power Plant, to mention a few, were delivered on-track, on-schedule and within budget.',
    ],
  },
  {
    date: 'Aug 2016 – Jun 2019',
    title: 'Senior Technical Assistant (STA) to Minister of State Petroleum Resources (HMSPR)',
    company: 'Nigerian National Petroleum Corporation (NNPC)',
    description: [
      'I was responsible for the coordination of all Technical activities; provide policy and strategic analysis to reposition the industry with respect to fiscal as well as regulatory for – upstream, midstream and downstream segments of the industry.',
      'I was responsible for the coordination of the following:',
      'Review and evaluate all technical submissions and advise the HMSPR.',
      'Develop policy and concepts strategies to implement the “7BigWins” agenda of the HMSPR aimed at achieving the implementation of the HMSPR’s voice and vision for the Oil and Gas Industry.',
      'Review, evaluation, update as well as provide guidance and advise on implementation of all Policy Guidelines and Procedures – upstream, midstream, and downstream.',
      'Develop a policy Memo via engagement of key stakeholders in order boost productivity and efficiency in the industry by:',
      '1. Reducing the cost of crude oil production from $32.00 per barrel to not more than $15.00;',
      '2. Crashing the contracting cycle from 36 months to not more than 6 months.',
      'Create and develop complete work plan for the attainment of the goals and objectives set by the HMSPR “7BigWins Project”.',
      'Restructuring of the MPR Agencies – Develop policies and Roadmap for the implementation “Single Regulator Industry Regulator”.',
      'The reviews of all Board Papers and advise HM’s actions.',
      'The preparation of the HMSPR’s budget as well as managing the budgets performance.',
      'The collation and review all daily, weekly and monthly composite Performance Reports of parastatals and advise on implementations, rewards and sanctions.',
      'Engaging both indigenous and international investors and review their proposal for various investments in the oils sector – upstream, downstream, etc.',
      'Preside over all Technical Meetings.',
      'And except otherwise directed, attended all FEC, EMT, Board, etc., meetings with the HMSPR and record all resolutions for implementations.',
    ],
  },
  {
    date: 'May 2016 – Aug 2016',
    title: 'Senior Technical Assistant (STA) to Group Managing Director',
    company: 'Nigerian National Petroleum Corporation (NNPC)',
    description: [
      'As the STA–GMD; the Engine Room of the GMD’s office, I was saddled with the responsibilities of the coordination and review of the all works, reports and memos from all the Directorates (7) for the GMD’s considerations and approval.',
      'I was responsible for the coordination of the following:',
      'The review and evaluation of all Technical Submissions, and advise the GMD.',
      'The functional activities of all other TAs, PAs, Consultants, and other secretarial staff working in the Office of the GMD.',
      'The reviews of NNPC Board Papers and advise GMD’s actions.',
      'The preparation of the GMD and STA’s budget as well as managing the budgets performance.',
      'The collation and review all daily, weekly and monthly composite Performance Reports of all the SBUs and CSUs and advise on implementations, rewards and sanctions.',
      'Attended all meetings with the GMD, recorded all resolutions for implementations and followed-up urgent action items to closure and was very discrete and maintained absolute confidentiality. Also forged and maintained healthy relations of trust with partners and external authorities on behalf of the GMD.',
    ],
  },
  {
    date: 'Oct 2009 – Feb 2011',
    title: 'Manager – Transformation Office',
    company: 'Nigerian National Petroleum Corporation (NNPC)',
    description: [
      'Coordinated the NNPC Fuel War Room – as the Secretary for the NNPC War Room, I provided the required leadership in the War Room that led to end of the fuel queue in March 2010, within 5 days ahead of the ministerial deadline and grew the daily national truck loading from 700 in February to over 1500 in March, which again, earned me the NNPC GMD’s prestigious Merit Award as Most Viable Soldier (MVS) in the War Room operations. In addition, I bagged the Most Innovative Soldier Award in the same War Room.',
      'Team Leader at WRPC Control Tower – as the Team Lead for WRPC Control Tower, I steered the daily refinery operations meeting (with top management in attendance) in order to:',
      'Ensure continuous operations for the next 3 months to increase capacity utilisation to 60% and simultaneously shift from an operator mind-set to a stand-alone profit centre mind-set. Key achievements included:',
      '1. Developed KPIs for each section;',
      '2. Carbon Black Plant restarted on-schedule after the repair of off-gas blower;',
      '3. Gradual shifting of mindsets within WRPC Control Tower from a business-as-usual to a can-do, out-of-the box solution mindset, etc.',
      'Team Leader KRPC FCCU Start-up: I was the Team lead for fixing the FCCU at Kaduna Refinery and coordinated the following activities:',
      '1. System checks and listing of maintenance tasks (over 200 items identified);',
      '2. Establishing clear management start-up plan with clear deadlines and responsibilities, and;',
      '3. Monitoring/tracking the daily progress and report back to Refining Services (RS) department/KRPC/ABUJA.',
    ],
  },
  {
    date: 'Feb 2011 – May 2016',
    title: 'Manager – Cost Engineering Division',
    company: 'Nigerian National Petroleum Corporation (NNPC)',
    description: [
      'As a foundational Manager of the Cost Engineering Division (CED), I was responsible for the setting up of the New CED, head-hunted staff to man the division, set up procedures, processes, and trained and mentored the staff between 2011 and 2016.',
      'As a General Manager CED, I provided executive level oversight on the Corporation’s Cost estimate works including the JV/PSC Partners projects and offered top management consultancy services on cost issues such as cost benchmarking & validation (EGTL Project), cost overruns, schedule slippage, claims, etc. Was also responsible for the Cost Engineering Roll-out across the Corporation in 2015.',
    ],
  },
  {
    date: 'Jun 2005 – Oct 2009',
    title: 'Head, Planning Department – Nigerian Content Division (now NCDMB)',
    company: 'Nigerian National Petroleum Corporation (NNPC)',
    description: [
      'Contributed in raising the NC values to about 40% in 2009, which stood at less than 10% in 2005, and provided support that led to the passage of the Nigerian Content Act of 2010 (appreciation from NNPC GMD conveyed by Letter of commendation dated 4th June, 2010).',
      'Responsible for ensuring that Nigerian Content policy guidelines were consistently applied to all projects from the very early stages of the contracting process through review and certification of:',
      '1. The Advertisements before publication;',
      '2. The ITTs (Invitation-To-Tender) documents before sending them to the bidders;',
      '3. Evaluation Plans and Criteria.',
      'Supervision of NCD involvement in the pre-qualification, technical, and commercial evaluations of the Nigerian Content scopes on all oil & gas projects.',
    ],
  },
  {
    date: '1994 – 2005',
    title: 'Project Controls / Cost Engineer',
    company: 'National Engineering and Technical Company (NETCO)',
    description: [
      'Single-handedly developed NETCO’s Charge-in Rate template/format still in use till date. This is an excel based matrix that encompasses all the cost items of NETCO and consolidates them into a one-line-item out of which the cost per-head per man-hour for the year is extracted (It is known as NETCO Charge-ins versus Charge-outs).',
      'Restored within five weeks, the slipping reputation of NETCO caused by schedule slippages, cost overruns and quality issues that Chevron complained about on Chevron-ABB-NETCO Facilities Engineering Design Office (FEDO). FEDO was a critical project for NETCO then as it was the main source of revenue paying all the staff salaries between 2000 – 2003.',
      'Wrote and presented many Technical Papers on issues of the oil and gas industry in Nigeria.',
      'Actively participated in preparation of bid proposals. Ensuring that the Project Controls Input such as schedules, logic and cost estimates; the preparation and monitoring/control tools and budgets are properly aligned.',
    ],
  },
  {
    date: '1992 – 1994',
    title: 'Design Engineer',
    company: 'National Engineering and Technical Company (NETCO)',
    description: [
      'Developed the design and supervised the prototype of Shell Petroleum Uzere Tapioca Drying Facilities, an initiative that earned me both GMD and MD NETCO’s Merit Awards in December, 1995.',
      'Single-handedly developed the NETCO Progress Measuring System (PMS) – which to-date is the only NETCO Tool for measuring/tracking physical progress on the projects – this replaces the Bechtel’s oracle that crashed after the company’s exit in 1996. It is a simple but powerful excel worksheet program.',
    ],
  },
];

const Experience = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const textColor = useColorModeValue('blue.700', 'white');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('blue.500', 'blue.300');

  return (
    <>
    <Box id="experience" bg={bgColor} color={textColor} py={20}>
      <Flex direction="column" align="justify" maxW="1200px" mx="auto" px={5} mt={16}>
        <Heading as="h3" size="xl" mb={10} borderBottom="4px solid" borderColor={borderColor} pb={2} align="center">
          Experience
        </Heading>
        {experiences.map((exp, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <Flex
              direction={{ base: 'column', md: 'row' }}
              mb={10}
              p={5}
              borderLeft="4px solid"
              borderColor={borderColor}
              bg={cardBgColor}
              boxShadow="lg"
              borderRadius="md"
              overflow="hidden"
              align="start"
            >
              <Box flex="1" pr={{ md: 5 }} mb={{ base: 5, md: 0 }}>
                <Text fontWeight="bold" fontSize="lg">{exp.date}</Text>
                <Text fontSize="xl">{exp.title}</Text>
              </Box>
              <Box flex="2">
                <Text fontWeight="bold" fontSize="xl">{exp.company}</Text>
                <Stack mt={2} spacing={2} pl={4} borderLeft="2px solid" borderColor={borderColor}>
                  {exp.description.map((desc, i) => (
                    <Text key={i} fontSize="md" lineHeight="tall" textAlign="justify">
                      {typeof desc === 'string' && desc.match(/^\d+\./) ? (
                        <Box as="span" pl={4}>
                          {desc}
                        </Box>
                      ) : (
                        <Box as="span">
                          • {desc}
                        </Box>
                      )}
                    </Text>
                  ))}
                </Stack>
              </Box>
            </Flex>
          </motion.div>
        ))}
      </Flex>
    </Box>
    <Footer />
    </>
  );
};

export default Experience;
