import React from 'react';
import { Box, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Footer from './Footer';

const awards = [
  {
    date: 'Dec 2021',
    title: 'Award of Excellence',
    organization: 'National Safety Awards, Media Center for Safety Reporting',
  },
  {
    date: 'Oct 2021',
    title: 'Presidential Service Award',
    organization: 'Nigerian Society of Engineers',
  },
  {
    date: 'Feb 2021',
    title: 'Appreciation Award for Outstanding Presentation',
    organization: 'Nigeria Association of Petroleum Explorationist (NAPE)',
  },
  {
    date: 'Jan 2018',
    title: 'Lead Speaker Appreciation Award',
    organization: 'Nigerian Society of Chemical Engineers at NSChE\'s 48th Annual Conference (GATEWAY 2018)',
  },
  {
    date: 'April 2010',
    title: 'Most Valuable Soldier',
    organization: 'NNPC GMD Merit Award as the Most Valuable Soldier in the NNPC Fuel War Room',
  },
  {
    date: 'April 2010',
    title: 'Most Innovative Soldier',
    organization: 'NNPC GMD Merit Award as the Most Innovative Soldier in the NNPC Fuel War Room',
  },
  {
    date: 'June 2006',
    title: 'Overall Best',
    organization: 'NNPC GMD Merit Award as the Overall Best Student in the NNPC COMDP Class 038',
  },
  {
    date: 'June 2006',
    title: 'Overall Best',
    organization: 'GED, Corporate Services Merit Award as the Overall Best Student in the Project Work - NNPC COMDP Class 038',
  },
  {
    date: 'June 2006',
    title: 'Overall Best',
    organization: 'GGM IT, Merit Award as the Overall Best Student in the Computer Appreciation - NNPC COMDP Class 038',
  },
  {
    date: 'June 2006',
    title: 'Overall Best',
    organization: 'GGM HR, Merit Award as the Overall Best Student in Course Work COMDP Class 038',
  },
  {
    date: '1995',
    title: 'Handling the Design of Shell Petroleum Dev Co Uzere Tapioca Drying Facilities',
    organization: 'NNPC GMD Merit Award in 1995 at NETCO for single-handedly handling the design of Shell Petroleum Dev Co Uzere Tapioca Drying Facilities',
  },
  {
    date: '1995',
    title: 'Most Outstanding Engineer',
    organization: 'NETCO Managing Director\'s Merit Award as the Most Outstanding Engineer of the Year',
  },
  {
    date: '1995',
    title: '2nd Prize - Young Managers\' Competition',
    organization: 'Awarded 2nd Prize of the 1995 Young Managers\' Competition, Lagos organized by NIM',
  },
];

const Awards = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('blue.700', 'white');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('blue.500', 'blue.300');

  return (
    <>
    <Box id="awards" bg={bgColor} color={textColor} py={20}>
      <Flex direction="column" align="justify" maxW="1200px" mx="auto" px={5} mt={16}>
        <Heading as="h3" size="xl" mb={10} borderBottom="4px solid" borderColor={borderColor} pb={2} align="center">
          Awards
        </Heading>
        {awards.map((award, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <Flex
              direction={{ base: 'column', md: 'row' }}
              mb={10}
              p={5}
              borderLeft="4px solid"
              borderColor={borderColor}
              bg={cardBgColor}
              boxShadow="lg"
              borderRadius="md"
              overflow="hidden"
              align="start"
            >
              <Box flex="1" pr={{ md: 5 }} mb={{ base: 5, md: 0 }}>
                <Text fontWeight="bold" fontSize="lg">{award.date}</Text>
              </Box>
              <Box flex="2">
                <Text fontWeight="bold" fontSize="xl" align="justify">{award.title}</Text>
                <Text fontSize="md" align="justify">{award.organization}</Text>
              </Box>
            </Flex>
          </motion.div>
        ))}
      </Flex>
    </Box>
    <Footer /> 
    </>
  );
};

export default Awards;
